.div {
  font-family: "Fira Code", monospace;
  font-weight: 300;
  font-variant-ligatures: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.p {
  font-size: 58px;
  letter-spacing: -4px;
}

.roxo {
  color: #bd93f9;
}

.laranja {
  color: #ffd711;
}

.verde {
  color: #50fa7b;
}
